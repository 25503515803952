import { ref , computed } from 'vue'
import { useStore } from 'vuex'
const useInformation = () => {
    const store = useStore()
    
    const isShowUpgradeLevelModal = computed(() => {
        return store.getters["admins/upgradeLevel"]
    }) 

    const toggleUpgradeLevelModal = (isOpen) => {
        store.commit("admins/upgradeLevel", { isOpen })
    }

    return {
        isShowUpgradeLevelModal,
        toggleUpgradeLevelModal
    }
}

export default useInformation