<template>
  <div class="bg-white p-4 border-r-10">
    <header class="d-flex align-items-center justify-content-between mb-3">
      <h1 class="text-primary-1 fs-3 fw-bold">
        {{ title ?? "-" }}
      </h1>
      <button @click="seeMoreAll(comments.length)" v-if="comments.length > 10" class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          p-2
          px-5
        ">
        see all
      </button>
    </header>

    <div class="table-feedback-student" v-if="headersComments.length > 0">
      <data-Table :value="comments.slice(0, initialShowTeachersNote)" dataKey="id" :rowHover="true" filterDisplay="menu"
        :globalFilterFields="fields" size="normal" :resizableColumns="true" columnResizeMode="fit"
        responsiveLayout="scroll" :rows="20" v-model:filters="filters" :loading="isLoading">
        <template #empty> No comments found. </template>
        <column v-for="(header, index) in headersComments" :key="index" :field="header.field" :header="header.name"
          :sortable="header.sortable" style="min-width: 14rem" :showFilterMatchModes="false">
          <template #body="{ data }">
            <p v-if="header.field === 'teacher'">{{ data[header.field] }}</p>
            <p v-if="header.field === 'student'">{{ data[header.field] }}</p>
            <p v-if="header.field === 'lesson_end'">
              {{ formatDate(data.lesson_end, 'american') }}
            </p>
            <!-- {{data}} -->

            <template v-if="header.field === 'feedback'">
              <div class="d-flex gap-3 align-items-center">
                <div class="d-block">
                  <p class="text-truncate mb-2" style="width:350px" v-if="data.feedback.find(p => p.type === 'content')">
                    {{ data.feedback.find(p => p.type === 'content')?.answer?.length > 47 ? data.feedback.find(p => p.type
                        === 'content')?.answer.substring(0, 47) : data.feedback.find(p => p.type === 'content')?.answer
                    }}</p>
                  <p class="text-truncate" style="width:350px"
                    v-if="data.feedback.find(p => p.type === 'content') && data.feedback.find(p => p.type === 'content')?.answer?.length > 47">
                    {{ data.feedback.find(p => p.type === 'content')?.answer?.length > 47 ? data.feedback.find(p => p.type
                        === 'content')?.answer.substring(47, 100) : data.feedback.find(p => p.type === 'content')?.answer
                    }}
                  </p>
                  <p v-if="data.feedback.length === 0 || !data.feedback.find(p => p.type === 'content')"
                    style="width:350px"> There is no comment from the teacher.</p>
                </div>
                <button @click="() => toggleFeedbackStudentOrTeacher(data)"
                  class="btn text-white bg-secondary-1 bg-hover-secondary-1 py-2 px-3 bl-shadow w-auto">
                  Show more
                </button>
              </div>

            </template>

          </template>
          <template #filter="{ filterModel }" v-if="header.filter">
            <div class="d-block" v-if="header.field === 'lesson_start'">
              <input-text type="text" name="lesson_start" placeholder="Search by date" v-model="filterModel.value" />
            </div>
            <div class="d-block" v-if="header.field === 'teacher'">
              <input-text type="text" name="teacher" placeholder="Search by Teacher Name" v-model="filterModel.value" />
            </div>
          </template>
        </column>
      </data-Table>
      <button v-if="initialShowTeachersNote < comments.length" @click="seeMoreAll(comments.length, 'more')" class="
          btn
          text-primary-1
          border-primary-1
          p-2
          px-5
          d-flex
          align-items-center
          mx-auto
        ">
        <i class="pi pi-plus-circle pe-2 fs-5"></i>
        See more
      </button>
    </div>

  </div>
</template>

<script>
import formatDate from "@/shared/utils/changeTexts/formatDate";
import useStudentProfile from "../../../modules/admin/composables/Members/Students/useStudentProfile";
import useStudents from "../../../modules/admin/composables/Members/Students/useStudents";
import moment from "moment";
export default {
  name: "UserTableFeedbackComponent",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Teacher's notes",
    },
    comments: {
      type: Array,
      default: [],
    },
    headersComments: {
      type: Array,
      default: [],
    },
  },
  setup() {
    const { toggleFeedbackStudentOrTeacher, filters, fields } = useStudents();
    const { seeMoreAll, initialShowTeachersNote } = useStudentProfile();
    return {
      moment,
      seeMoreAll,
      initialShowTeachersNote,
      useStudentProfile,
      filters,
      fields,
      toggleFeedbackStudentOrTeacher,
      formatDate,
    };
  },
};
</script>
