import { onBeforeMount, ref, watchEffect, computed } from "vue";
import { getFollowedClasses } from "../../../../../shared/helpers/getFollowedClasses";
import { useRoute } from "vue-router";
import formatDate from "@/shared/utils/changeTexts/formatDate";
import { ref as refDb, set, get, child, onValue } from "firebase/database";
import database from "@/firebase/config";
import { useStore } from "vuex";
import useSharedUI from "../../../../../shared/composables/useSharedUI";
const useStudent = () => {
  const route = useRoute();
  const { id } = route.params;
  const store = useStore();
  const isLoadingProfile = ref(true);
  const isUpgradeStudent = ref(false);

  const levelsInfo = ref([]);
  const initialShowTeachersNote = ref(10);
  const seeMoreAll = (total, view = "all") => {
    if (view === "all") initialShowTeachersNote.value = total;
    else initialShowTeachersNote.value = initialShowTeachersNote.value + 10;
  };

  const { sendNotificationUpgradeLevel } = useSharedUI();

  const studentProfile = ref({
    info: {},
    lastClass: {},
    historyLessons: [],
    classProgress: {},
    subtitles: [],
    totalClasses: 0,
  });
  const payload = ref({
    level_id: 1,
    description: "Passed the upgrade level test.",
    level_name: "",
  });
  const setLevels = () => {
    let levels_start = {};
    let levels = {};
    let levels_finish = {};
    let i = 0;
    studentProfile.value.subtitles?.forEach((title) => {
      let object = { ["" + i]: title };
      levels_start[title.level_id - 1] = {
        ...levels_start[title.level_id - 1],
        ...object,
      };
      i++;
    });
    Object.entries(levels_start).forEach(([key, value]) => {
      let level_info = {
        do_it: 0,
        total: 0,
        porcentage: 0,
        level_name_short: "",
        level_name: "",
      };
      Object.entries(value).forEach(([key2, value2]) => {
        if (value2.total_level_do === 1) {
          level_info.do_it = level_info.do_it + 1;
        }
        level_info.level_name_short = value2.level_name_short.toLowerCase();
        level_info.level_name = value2.level_name;
        level_info.total++;
      });
      level_info.porcentage = (level_info.do_it * 100) / level_info.total;
      levels[key] = level_info;
    });

    let initial_level = null;
    Object.entries(levels).forEach(([key, value]) => {
      if (initial_level === null) {
        initial_level = {
          ...value,
          initial_level_parent: value.level_name.substr(0, 7),
        };
        levels_finish[key] = initial_level;
      } else {
        if (
          initial_level.initial_level_parent !== value.level_name.substr(0, 7)
        ) {
          initial_level = {
            ...value,
            initial_level_parent: value.level_name.substr(0, 7),
          };
          levels_finish[key] = initial_level;
        }
      }
    });
    levelsInfo.value = levels_finish;
  };

  const updgradeStudent = async () => {
    isUpgradeStudent.value = true;
    const { id } = route.params;
    const { success } = await store.dispatch("admins/upgradeStudent", {
      payload: {
        level_id: payload.value.level_id,
        description: "Passed the upgrade level test.",
      },
      id,
    });
    if (success) {
      sendNotificationUpgradeLevel(
        studentProfile.value.info.token_firebase,
        payload.value.level_name
      );
      store.commit("admins/upgradeLevel", { isOpen: false });
      payload.value.level_id = 1;
      await getInfoStudent(id);
    }
    isUpgradeStudent.value = false;
  };

  const changeLevel = (e) => {
    payload.value.level_name = e.originalEvent.srcElement.textContent;
  };

  const getStatusStudent = async (token_firebase) => {
    const dbRefLastStatus = refDb(
      database,
      "Spanish/" + token_firebase + "/last_status"
    );
    const dbRefGet = refDb(database);
    onValue(dbRefLastStatus, (snapshot) => {
      if (snapshot.exists()) {
        if (snapshot.val() !== "online" && snapshot.val() !== "offline") {
          studentProfile.value.info.status = snapshot.val();
        } else {
          get(child(dbRefGet, "Spanish/" + token_firebase + "/status"))
            .then((snapshot2) => {
              studentProfile.value.info.status = snapshot2.val();
            })
            .catch((error) => {
              console.error(error);
            });
        }
      } else {
        studentProfile.value.info.status = "offline";
      }
    });

    const dbRefStatus = refDb(
      database,
      "Spanish/" + token_firebase + "/status"
    );
    onValue(dbRefStatus, (snapshot) => {
      if (snapshot.exists()) {
        if (snapshot.val() === "online") {
          get(child(dbRefGet, "Spanish/" + token_firebase + "/last_status"))
            .then((snapshot2) => {
              if (
                snapshot2.val() !== "online" &&
                snapshot2.val() !== "offline"
              ) {
                studentProfile.value.info.status = snapshot2.val();
              } else studentProfile.value.info.status = "online";
            })
            .catch((error) => {
              console.error(error);
            });
        } else studentProfile.value.info.status = snapshot.val();
      } else {
        studentProfile.value.info.status = "offline";
      }
    });
  };

  const getInfoStudent = async (id) => {
    const { success, json } = await store.dispatch("admins/getStudentById", id);
    if (success) {
      const {
        classes_progress,
        history_lessons,
        last_class_info,
        information,
        subtitles,
      } = json;
      studentProfile.value.info = information;
      studentProfile.value.lastClass = last_class_info;
      studentProfile.value.historyLessons = history_lessons.filter(item => item !== null);
      studentProfile.value.historyLessons.forEach((comment) => {
        comment.lesson_start_other = comment.lesson_start;
        comment.lesson_start = formatDate(comment.lesson_start, "american");
      });
      studentProfile.value.classProgress = classes_progress;
      studentProfile.value.subtitles = subtitles;
      studentProfile.value.totalClasses = classes_progress[0].total_lessons;
      setLevels();
      await getStatusStudent(information.token_firebase);
      payload.value.level_name = information.level;
      store.commit("admins/upgradeLevel", { data: studentProfile.value.info });
    }
    isLoadingProfile.value = false;
  };
  onBeforeMount(async () => {
    if (route.name === "admin-member-student-profile") {
      getInfoStudent(id);
    }
  });

  const levels = computed(() => {
    return store.getters["shared/getListings"].levels;
  });

  const student = computed(() => {
    return store.getters["admins/upgradeLevel"].data;
  });

  watchEffect(() => {
    const { data } = store.getters["admins/upgradeLevel"];
    if (Object.entries(data).length > 0) studentProfile.value.info = data;
  });

  // getStudentById
  return {
    isLoadingProfile,
    studentProfile,
    seeMoreAll,
    payload,
    student,
    levelsInfo,
    levels,
    updgradeStudent,
    changeLevel,
    initialShowTeachersNote,
  };
};
export default useStudent;
