<template>
  <div class="border-primary-1 py-1 px-4 h-content border-r-10 progress-levels">
    <div class="text-center">
      <h2 class="text-primary-0 fs-5 mb-1">
        {{ title }}
      </h2>

      <h1 class="fs-1 fw-bold text-accent-1">
        {{ currentLevel }}
      </h1>
      <div
        class="progress-levels-grid mt-2"
        v-if="Object.values(levels).length > 0"
      >
        <div v-for="(value, key) in levels" :key="key">
          <progress-bar
            :value="value.porcentage"
            :showValue="false"
            class="w-100"
            :class="`progress-${value?.level_name_short.toLowerCase()}`"
          />
          <p class="mt-1">{{ value.initial_level_parent }}</p>
        </div>
      </div>
      <p
        class="fs-6 text-primary-1 text-center"
        v-if="Object.values(levels).length === 0"
      >
        No found levels
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Level",
    },
    currentLevel: {
      type: String,
      default: "A1",
    },
    levels: {
      type: Object,
      default: {},
    },
  },
  setup() {
    return {
      /*       levels: {
        0: {
          do_it: 0,
          total: 4,
          porcentage: 0,
          level_name_short: "a1",
          level_name: "Level A1",
          initial_level_parent: "Level A",
        },
        2: {
          do_it: 0,
          total: 2,
          porcentage: 0,
          level_name_short: "b1",
          level_name: "Level B1",
          initial_level_parent: "Level B",
        },
        4: {
          do_it: 0,
          total: 2,
          porcentage: 0,
          level_name_short: "c1",
          level_name: "Level C1",
          initial_level_parent: "Level C",
        },
      }, */
    };
  },
};
</script>

<style lang="scss" scoped>
.progress-levels {
  width: 250px;
}
</style>