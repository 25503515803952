<template>
  <skeleton
    borderRadius="10px"
    height="13rem"
    class="skeleton-dark p-3"
    v-if="isLoading"
  />
  <div
    class="
      student-information
      p-4
      pe-5
      bg-primary-5
      border-r-10
      d-flex
      justify-content-between
      gap-3
    "
    v-else
  >
    <i
      class="pi pi-arrow-left text-primary-1 fs-4 fw-bold pointer"
      @click="goPreviousPage"
    ></i>

    <div class="d-flex gap-4 w-100 align-items-center">
      <div
        class="header-user- header-user-big w-100 text-center"
        style="max-width: 16rem"
      >
        <div class="position-relative text-center user-picture">
          <avatar :image="photo" shape="circle" />
          <div
            class="
              user-status
              w-auto
              bg-white
              py-1
              px-2
              bl-shadow
              d-flex
              gap-2
              align-items-center
            "
            :class="[!photo ? 'edit-show' : '']"
          >
            <div
              class="status"
              :class="
                typesUserStatus[status]
                  ? typesUserStatus[status].class
                  : 'bg-feedback-offline'
              "
              v-if="status"
            ></div>
            <span class="fs-6">
              {{ status ? typesUserStatus[status].text : "Loading..." }}
            </span>
          </div>
        </div>
      </div>
      <div class="w-100">
        <h1 class="fs-3 fw-bold">
          {{ name ?? "----------" }}
          <span v-if="lastname"> {{ lastname }}</span>
        </h1>
        <div class="d-flex gap-3">
          <span class="fs-6" v-if="age">{{ age ?? "--" }} years</span>
          <span class="fs-6" v-if="gender && age">|</span>
          <span class="fs-6" v-if="gender"> {{ gender ?? "-----" }} </span>
        </div>
        <p class="fs-6 my-1" v-if="timeZone">
          {{ timeZone ?? "--------------------" }}
        </p>
        <p class="fs-6 my-1" v-if="memberSince">
          
          <span class="fw-bold">Member since: </span>
          <span>
            {{
              memberSince
                ? moment(memberSince).format('MMMM DD, YYYY')
                : ""
            }}
          </span>
        </p>
      </div>
    </div>
    <template v-if="showCardRole">
      <div class="text-center" v-if="!showRole">
        <progress-levels-component
          :levels="levels"
          :currentLevel="currentLevel"
        />
        <button
          type="button"
          class="btn text-secondary-1 fs-1-1 text-hover-secondary-1 py-1 px-0"
          @click="toggleUpgradeLevel"
        >
          Upgrade level
        </button>
      </div>
      <div v-else class="text-center">
        <div
          class="border-primary-1 py-3 px-4 h-content border-r-10 mb-1"
          style="min-width: 140px"
        >
          <h2 class="text-primary-0 fs-5 mb-0">Role</h2>
          <p class="text-accent-1 fs-4 fw-bold">
            {{ role ?? "--" }}
          </p>
        </div>
        <button
          type="button"
          class="btn text-secondary-1 fs-1-1 text-hover-secondary-1 py-1 px-0"
          @click="changeRole"
          v-if="role === 'student'"
        >
          Change role
        </button>
        <!-- <p>Show Role</p> -->
      </div>
    </template>
  </div>
</template>

<script>
import ProgressLevelsComponent from "@/shared/components/Levels/ProgressLevelsComponent";
import typesUserStatus from "@/shared/types/typesUserStatus";
import moment from "moment";
import formatDate from "@/shared/utils/changeTexts/formatDate";
export default {
  components: {
    ProgressLevelsComponent,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    goPreviousPage: {
      type: Function,
      default: () => {},
    },
    photo: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    lastname: {
      type: String,
    },
    age: {
      type: String,
    },
    gender: {
      type: String,
    },
    timeZone: {
      type: String,
    },
    memberSince: {
      type: String,
    },
    status: {
      type: String,
      default: "offline",
    },
    toggleUpgradeLevel: {
      type: Function,
      default: () => {},
    },
    levels: {
      type: Array,
      default: [],
    },
    showRole: {
      type: Boolean,
      default: false,
    },
    showCardRole: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: "",
    },
    changeRole: {
      type: Function,
      default: () => {},
    },
    currentLevel: {
      type: String,
      default: "A1",
    },
  },
  setup() {
    return {
      typesUserStatus,
      moment,
      formatDate
    };
  },
};
</script>